<template>
  <div>
    <ServerError v-if="ServerError" />
    <v-layout pt-2 wrap justify-start>
      <v-flex xs12 text-left>
        <span
          style="font-family: opensansbold; font-size: 14px; color: #808080"
        >
          Graph - Payment
        </span>
      </v-flex>
    </v-layout>

    <v-layout wrap justify-end>
      <v-flex align-self-end xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu1"
          v-model="menu1"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          color="#68D389"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="fromDate"
              label="From Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              color="#68D389"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="fromDate"
            color="#68D389"
            no-title
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs12 sm6 md3 pa-5>
        <v-menu
          ref="menu2"
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          color="#68D389"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="toDate"
              label="To Date"
              outlined
              dense
              v-bind="attrs"
              v-on="on"
              color="#68D389"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="toDate"
            no-title
            color="#68D389"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="flag">
      <v-flex>
        <div id="chart" v-if="flag">
          <apexchart
            type="line"
            height="350"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
        <!-- <apexchart
          width="100%"
          height="300px"
          type="pie"
          :options="chartOptions"
          :series="series"
        ></apexchart> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      showSnackBar: false,
      timeout: 5000,
      ServerError: false,
      fromDate: new Date(),
      toDate: new Date(),
      menu1: false,
      menu2: false,
      graphData: [],
      flag: false,
      series: [
        {
          name: "Transaction",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          colors: "#eb4034",
        },
        title: {
          text: "Payments by Month",
          align: "left",
        },
        grid: {
          row: {
            colors: ["#FFE4B5", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        xaxis: {
          categories: [],
        },
      },
    };
  },
  watch: {
    fromDate() {
      this.checkDate();
      this.getData();
    },
    toDate() {
      if (this.fromDate) this.getData();
    },
  },
  mounted() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
      // this.fromDate="2021-04-03"
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
      // this.toDate="2021-08-03"
    }
    // this.getData();
  },
  methods: {
    checkDate() {
      let ndate = new Date().toISOString().substr(0, 10);
      if (this.fromDate == ndate) {
        // this.fromDate = null;
        const tomorrow = new Date(this.fromDate);
        tomorrow.setDate(tomorrow.getDate() - (6*30));
        this.fromDate = tomorrow.toISOString().substr(0, 10);
      }
    },
    getData() {
      this.flag = false;
      this.series[0].data = [];
      this.chartOptions.xaxis.categories = [];
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/report/getgraph",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.graphData = response.data.data.data;
            this.series[0].data = this.graphData.map((x) => x.amount);
            this.chartOptions.xaxis.categories = this.graphData.map(
              (x) => x.name
            );
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>