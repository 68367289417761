<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Analytics </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex>
        <v-tabs
          fixed-tabs
          v-model="tabValue"
          color="#68D389"
          centered
          slider-color="yellow"
        >
          <v-tab v-for="item in titleArray" :key="item.value">
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 v-if="(tabValue == 0)">
        <GenderGraphData v-bind:storage="dashboard" />
      </v-flex>
      <v-flex xs12 v-if="(tabValue == 1)">
        <NationalityGraphData v-bind:storage="dashboard" />
      </v-flex>
      <v-flex xs12 v-if="(tabValue == 2)">
        <PaymentGraphData v-bind:storage="dashboard" />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
// import Analytics from "./analytics";
import GenderGraphData from "./genderGraph";
import NationalityGraphData from "./nationalityGraph";
import PaymentGraphData from "./paymentGraph";

export default {
  components: {
    // Analytics,
    GenderGraphData,
    NationalityGraphData,
    PaymentGraphData,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      dashboard: {},
      tabValue: 0,
      titleArray: [
        { title: "GENDER", value: "gender" },
        { title: "NATIONALITY", value: "nationality" },
        { title: "PAYMENT", value: "payment" },
      ],
    };
  },
  watch: {
    tabValue() {
      // if (this.tabValue == 0) {
      //   this.getData();
      // } else if (this.tabValue == 1) {
      //   this.getReservations();
      // }
      this.getData()
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/dashboard/admin",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.dashboard = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = dt.slice(4, 10) + " " + year;
      return strTime;
    },
  },
};
</script>